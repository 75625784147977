import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Home from './Home'; // Import de la page Home
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} /> {/* Route pour la page d'accueil */}
        <Route path="/game/:code" element={<App />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// Si vous souhaitez mesurer les performances, passez une fonction à loguer ou envoyez à un endpoint analytique
reportWebVitals();
