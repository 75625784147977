import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function Home() {
    const [gameCode, setGameCode] = useState("");
    const navigate = useNavigate();

    const handleStartGame = () => {
        if (!gameCode.trim()) {
            alert("Veuillez entrer un code !");
            return;
        }
        navigate(`/game/${gameCode}`);
    };


    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                background: "radial-gradient(ellipse, rgba(35, 183, 255, 1) 50%, rgba(255, 255, 255, 0.5) 100%)",
                color: "white",
                textAlign: "center",
            }}
        >
            <h1 style={{ fontSize: "3rem", marginBottom: "20px" }}>Bienvenue sur HomeGaming</h1>
            <input
                type="text"
                placeholder="Entrez le code de votre jeu"
                value={gameCode}
                onChange={(e) => setGameCode(e.target.value)}
                style={{
                    padding: "10px",
                    fontSize: "16px",
                    margin: "20px 0",
                    borderRadius: "5px",
                    border: "none",
                    outline: "none",
                    textAlign: "center",
                }}
            />
            <button
                onClick={handleStartGame}
                style={{
                    padding: "10px 20px",
                    fontSize: "16px",
                    color: "white",
                    background: "#4CAF50",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                }}
            >
                Lancer le jeu
            </button>
        </div>
    );
}

export default Home;