import React, { useEffect, useState, useRef } from "react";
import mqtt from "mqtt";
import { useParams } from 'react-router-dom';

function App() {
  const { code } = useParams();

  // Ressource par défaut (URL)
  const [defaultResource, setDefaultResource] = useState(null);

  // File d'attente
  const [queue, setQueue] = useState([]);

  // Média en cours : { url, isStoppable, isDefault }
  const [currentMedia, setCurrentMedia] = useState(null);

  // Indique si le navigateur a autorisé la lecture (pas de blocage)
  const [hasInteracted, setHasInteracted] = useState(false);

  // Ref sur la balise <video> pour appeler .play()
  const videoRef = useRef(null);

  // Ref pour le timer sur les images
  const imageTimerRef = useRef(null);

  // ================================================
  // 1) Connexion MQTT
  // ================================================
  useEffect(() => {
    const client = mqtt.connect("wss://7u64nd.stackhero-network.com:443", {
      username: "dev",
      password: "lUnAagaPHJgKi18eQ5535F1zIsGPrHCb",
    });

    client.on("connect", () => {
      console.log("MQTT connecté !");
      client.subscribe(code, (err) => {
        if (err) console.error("Erreur abonnement : ", err);
        else console.log(`Abonné au topic ${code}`);
      });
    });

    client.on("message", (topic, message) => {
      console.log(`Message reçu sur ${topic} : ${message.toString()}`);
      handleIncomingMessage(message.toString());
    });

    client.on("error", (err) => {
      console.error("Erreur MQTT :", err);
    });

    return () => {
      client.end(true, () => {
        console.log("MQTT déconnecté");
      });
    };
    // eslint-disable-next-line
  }, []);

  // ================================================
  // 2) handleIncomingMessage
  // ================================================
  const handleIncomingMessage = (rawMsg) => {
    let parsed;
    try {
      parsed = JSON.parse(rawMsg);
    } catch (e) {
      console.error("Message non JSON :", rawMsg);
      return;
    }

    // On veut un tableau d'objets
    let arrayOfObjects = [];
    if (Array.isArray(parsed)) {
      arrayOfObjects = parsed;
    } else if (parsed && typeof parsed === "object") {
      arrayOfObjects = [parsed];
    } else {
      console.error("Le message JSON n'est ni un tableau ni un objet :", parsed);
      return;
    }

    // Mettre à jour la ressource par défaut s'il existe un objet isDefault=true
    const defaultObj = arrayOfObjects.find(obj => obj.isDefault === true);
    if (defaultObj && defaultObj.url) {
      setDefaultResource(defaultObj.url);
    }

    // Récupérer l'objet le plus récemment mis à jour
    const lastUpdatedObj = arrayOfObjects.find(obj => obj.isLastUpdated === true);
    if (!lastUpdatedObj) {
      return;
    }

    const { url, isStoppable, isDefault } = lastUpdatedObj;
    if (!url) {
      console.error("Pas d'URL dans l'objet isLastUpdated");
      return;
    }

    // Si on n'a rien en cours => on lit directement
    if (!currentMedia) {
      setCurrentMedia({ url, isStoppable: !!isStoppable, isDefault: !!isDefault });
      return;
    }

    // Sinon, on regarde si le média en cours est stoppable
    if (currentMedia.isStoppable) {
      // On interrompt et on lit le nouveau
      setCurrentMedia({ url, isStoppable: !!isStoppable, isDefault: !!isDefault });
    } else {
      // currentMedia n'est pas stoppable => on ajoute à la queue
      setQueue((old) => [...old, { url, isStoppable: !!isStoppable, isDefault: !!isDefault }]);
    }
  };

  // ================================================
  // 3) Surveille si currentMedia disparaît
  // ================================================
  useEffect(() => {
    if (!currentMedia) {
      if (queue.length > 0) {
        playNextInQueue();
      } else if (defaultResource) {
        setCurrentMedia({ url: defaultResource, isDefault: true, isStoppable: false });
      }
    }
  }, [currentMedia, queue, defaultResource]);

  // ================================================
  // 4) playNextInQueue
  // ================================================
  const playNextInQueue = () => {
    if (imageTimerRef.current) {
      clearTimeout(imageTimerRef.current);
      imageTimerRef.current = null;
    }

    setQueue((oldQueue) => {
      if (!oldQueue.length) {
        // plus rien => revenir à la ressource par défaut si elle existe
        if (defaultResource) {
          setCurrentMedia({ url: defaultResource, isDefault: true, isStoppable: false });
        } else {
          setCurrentMedia(null);
        }
        return [];
      }
      const [next, ...rest] = oldQueue;
      setCurrentMedia(next);
      return rest;
    });
  };

  // ================================================
  // 5) handleMediaEnd
  // ================================================
  const handleMediaEnd = () => {
    if (queue.length > 0) {
      playNextInQueue();
    } else if (defaultResource) {
      setCurrentMedia({ url: defaultResource, isDefault: true, isStoppable: false });
    } else {
      setCurrentMedia(null);
    }

    // On peut choisir de redemander le clic pour la vidéo suivante
    // (si on veut forcer à chaque fois)
    // Sinon on laisse en true pour ne pas ré-afficher l'overlay
    // setHasInteracted(false);
  };

  // ================================================
  // 6) Tenter l'autoplay quand currentMedia change
  // ================================================
  useEffect(() => {
    // S'il n'y a pas de currentMedia ou si ce n'est pas une vidéo, on s'en fout
    if (!currentMedia) return;
    if (!videoRef.current) return;

    // Si c'est une vidéo, on tente de la lancer
    // (S'il y a de l'audio, le navigateur peut bloquer)
    videoRef.current
      .play()
      .then(() => {
        // Pas de blocage => on masque l'overlay
        setHasInteracted(true);
      })
      .catch((err) => {
        console.warn("Autoplay bloqué ou échec de lecture :", err);
        // On affiche l'overlay => forcer le clic
        setHasInteracted(false);
      });
  }, [currentMedia]);

  // ================================================
  // 7) handlePlayClick => si autoplay bloqué
  // ================================================
  const handlePlayClick = () => {
    if (videoRef.current) {
      videoRef.current.play().catch((err) => {
        console.error("Échec lors du play() suite au clic :", err);
      });
    }
    // L'utilisateur a cliqué => plus besoin d'overlay
    setHasInteracted(true);
  };

  // ================================================
  // 8) renderMedia
  // ================================================
  const renderMedia = () => {
    if (!currentMedia) return null;

    const { url, isDefault } = currentMedia;
    // Déterminer le type du média
    const mediaType = (() => {
      if (url.match(/\.(jpg|jpeg|png|gif|webp)$/i)) return "image";
      if (url.match(/\.(mp4|webm|ogg)$/i)) return "video";
      if (url.match(/\.(mp3|wav|aac|flac)$/i)) return "audio";
      if (url.includes("vimeo.com")) return "vimeo";
      if (url.includes("youtube.com") || url.includes("youtu.be")) return "youtube";
      return "unsupported";
    })();

    const shouldLoop = isDefault;

    // Gestion de l'image : timer de 30s si pas default
    if (mediaType === "image" && !isDefault) {
      if (!imageTimerRef.current) {
        imageTimerRef.current = setTimeout(() => {
          handleMediaEnd();
        }, 30000);
      }
    } else if (mediaType === "image" && isDefault) {
      if (imageTimerRef.current) {
        clearTimeout(imageTimerRef.current);
        imageTimerRef.current = null;
      }
    }

    // Style global
    document.body.style.backgroundColor = "black";
    document.body.style.overflow = "hidden";

    switch (mediaType) {
      case "image":
        return (
          <img
            src={url}
            alt=""
            style={{ width: "100vw", height: "100vh", objectFit: "contain" }}
          />
        );

      case "video":
        return (
          <div style={{ position: "relative" }}>
            <video
              ref={videoRef}
              src={url}
              // On met autoPlay pour essayer, mais s'il y a du son,
              // c'est le useEffect qui gère le blocage
              autoPlay
              loop={shouldLoop}
              onContextMenu={(e) => e.preventDefault()}
              disablePictureInPicture
              controlsList="nodownload nofullscreen noremoteplayback"
              style={{ width: "100vw", height: "100vh", objectFit: "contain" }}
              onEnded={() => {
                if (!shouldLoop) handleMediaEnd();
              }}
            />

            {/* 
              Overlay => si le navigateur a bloqué l'autoplay.
              hasInteracted = false => signifie qu'on DOIT cliquer
            */}
            {!hasInteracted && (
              <div
                onClick={handlePlayClick}
                style={{
                  position: "absolute",
                  top: 0, left: 0, right: 0, bottom: 0,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer"
                }}
              >
                <span style={{ color: "white", fontSize: "1.5rem" }}>
                  Cliquer pour lancer la vidéo
                </span>
              </div>
            )}
          </div>
        );

      case "audio":
        return (
          <audio
            src={url}
            autoPlay
            controls
            loop={shouldLoop}
            onEnded={() => {
              if (!shouldLoop) handleMediaEnd();
            }}
            style={{ width: "100%" }}
          />
        );

      case "vimeo":
      case "youtube":
        return (
          <p style={{ color: "white" }}>
            Exemple d’iframe à gérer pour {mediaType} => {url}
          </p>
        );

      default:
        if (!isDefault) handleMediaEnd();
        return <p style={{ color: "white" }}>Format non supporté : {url}</p>;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {currentMedia ? renderMedia() : (document.body.style.backgroundColor = "black")}
    </div>
  );
}

export default App;
